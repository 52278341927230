import React from 'react'
import SideBar from './SideBar'
import Header from './Header'
import Home from './Home'

const Dashboard = () => {
    return (
        <>
            <div className='wrapper'>
                <SideBar />
                <div className='main-panel'>
                    <Header />
                    <Home />
                </div>
            </div>


        </>
    )
}

export default Dashboard
import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import axios from 'axios';

const GlobalFilter = ({ filter, setFilter }) => (
  <input
    value={filter || ''}
    onChange={(e) => setFilter(e.target.value || undefined)}
    placeholder="Search..."
    style={{ padding: '5px', marginLeft: '10px' }}
  />
);

const LeadTable = ({data,loading,duplicateSum}) => {
 

  const columns = useMemo(
    () => [
      {
        Header: 'S.No', // Serial Number Column
        accessor: 'serialNumber', // New accessor for serial number
        Cell: ({ row }) => row.index + 1, // Displaying index + 1 as serial number
      },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Class', accessor: 'class' },
      { Header: 'Phone Number', accessor: 'phone_number' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Source From', accessor: 'source_from' },
      { Header: 'Remarks', accessor: 'remarks' },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : 'N/A'),
      },
      {
        Header: 'Duplicate',
        accessor: 'dublicate',
        Cell: ({ value }) => (value >= 1 ? 'Yes' : 'No'),
      },
    ],
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter, pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <hr />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
          color: '#000'
        }}
      >
      <h2>Leads</h2>

      <div style={{ marginBottom: '10px' }}>
          Total Leads : <strong>{data.length + duplicateSum}</strong> 
        </div>

        <div style={{ marginBottom: '10px' }}>
          Unique Leads : <strong>{data.length}</strong> 
        </div>
        {/* Display the sum of duplicate counts */}
        <div style={{ marginBottom: '10px' }}>
          Total Duplicates : <strong>{duplicateSum}</strong> 
        </div>

      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <span>
          Show{' '}
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[5, 10, 20, 25, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>{' '}
          entries
        </span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '10px' }}>Search</span>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>



      <table
        {...getTableProps()}
        style={{ marginTop: '20px', width: '100%', border: '1px solid #ccc' }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    border: '1px solid #ccc',
                    padding: '10px',
                    background: '#f0f0f0',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            // Apply conditional row styling
            const isDuplicate = row.original.dublicate >= 1;
            return (              
              <tr
                key={index}
                {...row.getRowProps()}
                style={{
                  backgroundColor: isDuplicate ? 'rgba(255, 0, 0, 0.1)' : 'white',
                }}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={{ border: '1px solid #ccc', padding: '10px' }}
                  >
                    {/* {cell.render('Cell')} */} 
                    {cell.value != "null"?cell.render('Cell'):''}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div style={{ marginTop: '10px' }}>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '50px' }}
          />
        </span>
      </div>
    </div>
  );
};

export default LeadTable;

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { GlobalLoading, showLoading } from "react-global-loading";
import 'react-toastify/dist/ReactToastify.css';
import LeadTable from '../Component/LeadTable';

const Home = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [uploadedData, setUploadedData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [duplicateSum, setDuplicateSum] = useState(0);
  const [sources, setSources] = useState([]);
  const [sourceFrom, setSourceFrom] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const validExtensions = ['.xls', '.xlsx'];
    if (file) {
      const fileExtension = file.name.split('.').pop();
      if (!validExtensions.includes(`.${fileExtension}`)) {
        setErrorMessage('Please upload an Excel file (.xls or .xlsx)');
        setSelectedFile(null);
        return;
      }
      setErrorMessage('');
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (!sourceFrom) {  // Check if source is selected
      toast.error('Please select a source.');
      return;
    }
  
    if (!selectedFile) {
      toast.error('Please select a file first.');
      return;
    }
  
    setIsUploading(true);
    showLoading(true); // Show full-screen loading spinner
  
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('source_from', sourceFrom);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASEURL}/app/upload/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Response data-----',JSON.stringify(response));
      if(response.data.status == 200){
          toast.success(`File "${selectedFile.name}" uploaded successfully!`);
          setUploadedData(response.data);
          setShowTable(true);
          fetchLeads();

          // Clear the file input after a successful upload
          setSelectedFile(null);
          if (inputRef.current) {
          inputRef.current.value = null;
          }
      }
      
    } catch (error) {
      console.error('Upload error:', error);
      toast.error('An error occurred while uploading the file.');
    } finally {
      setIsUploading(false);
      showLoading(false); // Hide full-screen loading spinner
    }
  };
  

  const fetchLeads = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASEURL}/app/get/leads`);
      const resData = response.data.data;

      setData(resData);

      // Calculate the sum of duplicates where count > 1
      const sum = resData.reduce((acc, lead) => {
        return lead.dublicate >= 1 ? acc + lead.dublicate : acc;
      }, 0);
      setDuplicateSum(sum);
    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/app/get/lead/source`);
        if (Array.isArray(response.data.data)) {
          const resData = response.data.data;
          const sourceArray = resData.map(item => ({ id: item.id, source_from: item.source_from }));
          setSources(sourceArray);
        } else {
          setErrorMessage('Invalid data format');
        }
        setLoading(false);
      } catch (error) {
        setErrorMessage(error.message);
        setLoading(false);
      }
    };
    fetchSources();
  }, []);

  const handleSourceChange = (e) => {
    setSourceFrom(e.target.value);
  };

  return (
    <div className="container">
      <GlobalLoading />
      <ToastContainer />
      <div className="page-inner">
        <div>
          <h1>SRMPS Lead Management</h1>
          <p className="text-end">
            <a href="/template.xlsx" download>
              <button type="button" className="btn btn-primary">
                Excel Sheet Template Download <i className="fas fa-file-excel"></i>
              </button>
            </a>
          </p>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div className="mb-3">
            <select
              id="source"
              value={sourceFrom}
              onChange={handleSourceChange}
              style={{ padding: '8px', borderRadius: '4px' }}  >
              <option value="">Select a source</option>

              {sources && sources.length > 0 ? (
                sources.map((source, index) => (
                  <option key={index} value={source.id}>
                    {source.source_from}
                  </option>
                ))
              ) : (
                <option value="">No sources available</option>
              )}
            </select>
          </div>
          <div className="mb-3 col-xl-2">
            <input
              ref={inputRef}
              className="form-control"
              type="file"
              id="formFile"
              onChange={handleFileChange}
            />
          </div>

          <button
            onClick={handleUpload}
            style={{
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              padding: '10px 20px',
              cursor: 'pointer',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
            }}
            disabled={isUploading}
          >
            Submit
          </button>

        </div>

        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

        <LeadTable duplicateSum={duplicateSum} data={data} loading={loading} />
      </div>
    </div>
  );
};

export default Home;

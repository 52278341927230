import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/login'; 

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
  
    setIsAuthenticated(true);
  };

  return (
    <Router>
      <div className='wrapper'>
        <Routes>
          <Route path='/login' element={!isAuthenticated ? <Login onLogin={handleLogin} /> : <Navigate to='/dashboard' />} />
          <Route path='/dashboard' element={<Dashboard />} />
         
          <Route path='/' element={<Navigate to='/login' />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;




// import React, { useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import './App.css';
// import Dashboard from './Pages/Dashboard';
// import Login from './Pages/login';  // Add the Login component

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const handleLogin = () => {
  
//     setIsAuthenticated(true);
//   };

//   return (
//     <Router>
//       <div className='wrapper'>
//         <Routes>
//           <Route path='/login' element={!isAuthenticated ? <Login onLogin={handleLogin} /> : <Navigate to='/dashboard' />} />
//           <Route path='/dashboard' element={isAuthenticated ? <Dashboard /> : <Navigate to='/login' />} />
//           <Route path='/' element={<Navigate to='/login' />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;

